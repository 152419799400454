import React, { useContext } from 'react'
import { MdOutlineLanguage } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import defaultLogo from './../../assets/icons/wajen.png'
import { ShopContext } from '../../context/shop-context';
import { Instagram   } from 'react-content-loader';

function TopBar() {
  const {profile, isLoading} = useContext(ShopContext);
  const [t, i18n] = useTranslation();
  const localhost_url = process.env.REACT_APP_IMAGE_URL;
  const logo =  localhost_url + profile.logo;

  return (
    <div>   
{isLoading ? <Instagram  /> :
     <div className="container-fluid m-0 px-1 py-0" dir='ltr'>   
     <nav className="navbar  m-2 py-0 p- bg-light">
      
  <img src={logo} width="60px"/>
 
  {i18n.language == "en" ?
  <button onClick={()=>i18n.changeLanguage('ar')} className="btn btn-primary text-white mx-2 px-2 rounded-2" href="#" role="button"> 
  
  العربية
  &nbsp;<MdOutlineLanguage size="22px"/>
  </button>
  :
  <button onClick={()=>i18n.changeLanguage('en')} className="btn btn-primary text-white mx-2 px-2 rounded-2" href="#" role="button"> 
  
  &nbsp;<MdOutlineLanguage size="22px"/> English
  </button>
}
</nav>

</div>
}
    </div>
  )
}

export default TopBar
